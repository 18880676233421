import { handleApiResponse, generatePATCHApiData, generatePOSTApiData } from 'utils/handleApi';
import pendingPromise from 'utils/pendingPromise';
// import deserialise from 'utils/deserialise';
// import { toQueryString } from 'utils/utils';

let API_BASE = 'https://thundr.s-prod.pow.io';
if (typeof window !== 'undefined' && location.search.includes('use-local-api')) {
	API_BASE = 'http://localhost:12637';
}

// should set this in the baseDoc or using app server after user login as __API_KEY__ or something
// const API_KEY = typeof window !== 'undefined' && (window.__AUTH_USER__.apiKey || localStorage['__API_KEY__']);
const useCache = typeof window !== 'undefined' && location.search.includes('use-cache');
const CACHE_DURATION_MS = 12 * 60 * 60 * 1000;

let cacheDB;
if (useCache && window.indexedDB) {
	cacheDB = new Promise((resolve, reject) => {
		let dbReq = indexedDB.open('ThundrCacheDB', 1);
		dbReq.onupgradeneeded = event => {
			let db = event.target.result;
			db.createObjectStore('cache', { keyPath: 'path' });
		};
		dbReq.onerror = reject;
		dbReq.onsuccess = event => resolve(event.target.result);
	});
}
async function getFromCache(path) {
	let db = await cacheDB;
	let objStore = db.transaction(['cache']).objectStore('cache');
	let readReq = objStore.get(path);
	let promise = pendingPromise();
	readReq.onerror = promise.reject;
	readReq.onsuccess = evt => {
		let result = evt.target.result;
		if (!result?.time || result.time + CACHE_DURATION_MS < Date.now()) {
			return promise.resolve(null);
		}
		promise.resolve(result.value);
	};
	return promise;
}
async function saveToCache(path, data) {
	let db = await cacheDB;
	let objStore = db.transaction(['cache'], 'readwrite').objectStore('cache');
	let promise = pendingPromise();
	let req = objStore.put({
		path: path,
		value: data,
		time: Date.now()
	});
	req.onerror = promise.reject;
	req.onsuccess = promise.resolve;
	return promise;
}

async function apiCall(path, options, deserialize) {
	if (options?.qs) {
		path += '?' + new URLSearchParams(options.qs).toString();
		delete options.qs;
	}

	let res = await fetch(API_BASE + path, Object.assign({
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		credentials: 'include'
	}, options));
	let data = await handleApiResponse(res);
	if (deserialize) {
		data = data.data;
	}
	return data;
}

function mergeData(oldData, newData) {
	if (!oldData || !newData) {
		return newData || oldData;
	}
	if (Array.isArray(oldData)) {
		return oldData.concat(newData);
	}
	if (typeof newData === 'object') {
		let allKeys = [...new Set(Object.keys(oldData).concat(Object.keys(newData)))];
		return Object.fromEntries(allKeys.map(key => [key, mergeData(oldData[key], newData[key])]));
	}
	return newData;
}

async function getRemoteData(path, qs, deserialize) {
	let res = await apiCall(path, { method: 'GET', qs, cache: 'no-store' }, false);
	let pageSize = +(res?.meta?.pageSize || res.data?.length);
	if (!res?.meta?.hasMore || !pageSize) {
		return deserialize ? res.data : res;
	}
	let nextRes = await getRemoteData(path, { ...qs, offset: (qs?.offset || 0) + pageSize }, false);
	let data = mergeData(res.data, nextRes.data);
	return deserialize ? data : { data, meta: nextRes.meta };
}

export const apiGet = async (apiPath, qs, deserialize, allowCache) => {
	if (typeof window === 'undefined') {
		return Promise.reject('Trying to call API at compile time');
	}
	let cached = allowCache && useCache && cacheDB;
	if (cached) {
		try {
			let cachedData = await getFromCache(apiPath);
			if (cachedData) {
				return cachedData;
			}
		} catch (e) {}
	}
	let data = await getRemoteData(apiPath, qs, deserialize);
	if (cached) {
		try {
			await saveToCache(apiPath, data);
		} catch (e) {
			console.log('Error saving api data to cache', apiPath, e);
		}
	}
	return data;
};

export const apiCreate = (apiPath, body, deserialize) => {
	if (typeof body !== 'string') {
		body = JSON.stringify(body);
	}
	return apiCall(apiPath, { method: 'POST', body }, deserialize);
};

export const apiUpdate = (apiPath, body, deserialize) => {
	if (typeof body !== 'string') {
		body = JSON.stringify(body);
	}
	return apiCall(apiPath, { method: 'PATCH', body }, deserialize);
};

export const apiDelete = (apiPath, qs, deserialize) => {
	return apiCall(apiPath, { method: 'DELETE', qs }, deserialize);
};

const idQS = id => id ? { id } : null;

export const getAppData = (id) => apiGet('/app-data/apps', idQS(id), true, !id);
export const getAppDataMerged = (id) => apiGet('/app-data/merged', { id }, true);
export const getTitleData = (id) => apiGet('/app-data/titles', idQS(id), true, !id);
export const getStudioRegionData = (id) => apiGet('/app-data/studio-regions', idQS(id), true, !id);
export const getStudioData = (id) => apiGet('/app-data/studios', idQS(id), true, !id);
export const getRegionData = (id) => apiGet('/app-data/regions', idQS(id), true, !id);
export const getBaseData = (id) => apiGet('/app-data/bases', idQS(id), true, !id);
export const getListData = () => apiGet('/app-data/list', null, true, true);
export const getChanges = (type, id) => apiGet('/app-data/changes', { type, id }, true);
export const getAppsPerCloudfront = (cloudfrontId) => apiGet('/apps-per-cloudfront', { cloudfrontId }, true, true);

export const getScreeningCounts = (q = {}) => apiGet('/proxy/stdata/stats/screening_counts', q, true);
export const getVisitCounts = () => apiGet('/proxy/stdata/insights/visit_counts', null, true);
export const getSessionCounts = () => apiGet('/proxy/stdata/insights/active_session_counts', null, true);
export const getInsightsCounts = (eventSlug, q) => apiGet(`/proxy/stdata/insights/counts/${eventSlug}`, q, true);
export const getInsightsScreenings = (q) => apiGet('/proxy/stdata/insights/screenings', q);
export const getEventStats = (q) => apiGet('/proxy/stdata/insights/event_stats', q, true);
export const getScreenings = (q) => apiGet('/proxy/stdata/screenings', q);
export const getMovies = (q) => apiGet('/proxy/stdata/movies', q);
export const getMovieMaster = (q) => apiGet('/proxy/stdata/movie_masters', q, true);
export const getMovieMasterFromID = (id) => apiGet('/proxy/stdata/movie_masters/' + id, null, true);
export const getProviderTitles = (q) => apiGet('/proxy/stdata/movies', q, true);
export const getPTSuggestions = (titles) => apiGet('/proxy/stdata/movies/suggestions', { titles }, true);
export const getFormats = (q) => apiGet('/proxy/stdata/formats', q, true, useCache);
export const getHealthCheck = () => apiGet('/proxy/stdata/imports/health_check');
export const getProvidersList = () => apiGet('/proxy/stdata/providers/list', null, true);

const urlType = type => !!type && type !== 'app_data' ? '/' + type.replace('_', '-') : '/apps';

export const createAppData = (type, data) => apiCreate(`/app-data${urlType(type)}`, generatePOSTApiData(data), true);
export const createMovieMasterId = (update) => apiCreate('/proxy/stdata/movie_masters', { data: { attributes: update } }, true);
export const createProviderTitle = (update) => apiCreate('/proxy/stdata/movies', { data: { attributes: update } }, true);
export const createFormat = (name) => apiCreate('/proxy/stdata/formats', { data: { attributes: { name: name } } }, true);
export const createStudio = ({ slug, name, base }) => apiCreate('/app-data/studios', { attributes: { meta: { studio: { slug, name } } }, rel: { base } }, true);

export const updateAppData = (id, type, data, original) => apiUpdate(`/app-data${urlType(type)}`, generatePATCHApiData(data, original, id), true);
export const updateProviderTitle = (id, update) => apiUpdate(`/proxy/stdata/movies/${id}`, { data: { attributes: update } }, true);

export const deleteAppData = (id, type) => apiDelete(`/app-data${urlType(type)}`, { id }, true);

// Hack to make scripting easier: expose updateAppData
if (typeof window !== 'undefined') {
	window.updateAppData = updateAppData;
}
